import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row } from 'react-bootstrap';
import { businessTypes } from 'data/businessTypes';

const PlanForm = ({ register, errors }) => {
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const handleBusinessTypeChange = event => {
    setIsOtherSelected(event.target.value === 'Other');
  };

  return (
    <Col>
      <WizardInput
        type="text"
        label="Website"
        name="website"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('website', {
            required: 'Website is required',
            pattern: {
              value: /^(https):\/\/[^ "]+$/i,
              message: 'Website URL must be valid'
            }
          })
        }}
      />

      <WizardInput
        type="select"
        label="Tier plan"
        name="tierPlan"
        placeholder="Select tier plan..."
        errors={errors}
        options={['Tier 1', 'Tier 2', 'Tier 3']}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('tierPlan', {
            required: 'Tier plan is required'
          })
        }}
      />

      <WizardInput
        type="text"
        label="Phone number"
        name="phoneNumber"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('phoneNumber', {
            required: 'Phone number is required'
          })
        }}
      />
      <WizardInput
        type="text"
        label="Business name"
        name="businessName"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('businessName', {
            required: 'Business name is required'
          })
        }}
      />

      <WizardInput
        type="textarea"
        label="Business Address"
        name="businessAddress"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('businessAddress', {
            required: 'Business address is required'
          })
        }}
      />

      <WizardInput
        type="text"
        label="Zip Code"
        name="zipCode"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('zipCode', {
            required: 'Zip Code is required',
            pattern: {
              value: /^\d{5}(-\d{4})?$/,
              message: 'Zip Code must be valid'
            }
          })
        }}
      />

      <WizardInput
        type="text"
        label="State"
        name="state"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('state', {
            required: 'State is required'
          })
        }}
      />

      <WizardInput
        type="text"
        label="City"
        name="city"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('city', {
            required: 'City is required'
          })
        }}
      />
      <Row className="d-flex flex-row">
        <WizardInput
          type="select"
          label="Business Type"
          name="businessType"
          placeholder="Select business type..."
          errors={errors}
          options={businessTypes}
          formGroupProps={{
            className: `mb-3 ${isOtherSelected ? 'w-50' : ''}`
          }}
          formControlProps={{
            ...register('businessType', {
              required: 'Business type is required',
              onChange: e => {
                handleBusinessTypeChange(e);
              }
            })
          }}
        />

        {isOtherSelected && (
          <WizardInput
            type="text"
            label="Specify Other Business Type"
            name="otherBusinessType"
            errors={errors}
            formGroupProps={{
              className: `mb-3 ${isOtherSelected ? 'w-50' : ''}`
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('otherBusinessType', {
                required: 'Please specify your business type'
              })
            }}
          />
        )}
      </Row>
    </Col>
  );
};

PlanForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default PlanForm;
