import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import DeleteTemplateModal from 'components/templates/DeleteTemplateModal';
import Lottie from 'lottie-react';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import './styles.scss';

const EmailTemplatesPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState();

  const fetchTemplates = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(`/templates?type=email`);
      setTemplates(response.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to fetch templates');
      }
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between mb-4">
        <h4>Email Templates</h4>
        <Link to="/templates/email/create">
          <Button variant="outline-primary">
            <FaPlus />
            {` `}
            Create
          </Button>
        </Link>
      </div>
      <Row className="gap-3">
        {isFetching ? (
          <Col
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center min-vh-75"
          >
            <Lottie
              animationData={infiniteLoop}
              loop={true}
              style={{ height: '200px', width: '200px' }}
            />
            <h3 className="text-center">Loading...</h3>
          </Col>
        ) : templates.length > 0 ? (
          templates.map((template, index) => (
            <Col md="12" xl="3" key={`template-${index}`} className="template">
              <Card>
                <Card.Header className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom">
                  <Card.Title as="h5" id="contained-modal-title-vcenter">
                    {template.name}
                  </Card.Title>
                  <div className="d-flex gap-2">
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          style={{ position: 'fixed', zIndex: 1000 }}
                          id="overlay-trigger-example"
                        >
                          Edit
                        </Tooltip>
                      }
                    >
                      <Link to={`/templates/email/${template.id}`}>
                        <Button variant="outline-info">
                          <FaEdit />
                        </Button>
                      </Link>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          style={{ position: 'fixed', zIndex: 1000 }}
                          id="overlay-trigger-example"
                        >
                          Delete
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          setDeletingTemplate(template);
                          setShowDeleteTemplateModal(true);
                        }}
                      >
                        <AiFillDelete />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </Card.Header>
                <Card.Body style={{ height: 580, overflowY: 'auto' }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: template.content?.html }}
                  />
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col xs={12} className="text-center">
            <h5>No templates found</h5>
          </Col>
        )}
      </Row>
      {deletingTemplate && (
        <DeleteTemplateModal
          showDeleteTemplateModal={showDeleteTemplateModal}
          setShowDeleteTemplateModal={setShowDeleteTemplateModal}
          deletingTemplate={deletingTemplate}
          setDeletingTemplates={setDeletingTemplate}
          fetchTemplates={fetchTemplates}
        />
      )}
    </div>
  );
};

export default EmailTemplatesPage;
