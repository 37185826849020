import React from 'react';
import Flex from 'components/common/Flex';

import { Card, Col, Row } from 'react-bootstrap';

const SentEmailVolume = () => {
  return (
    <Card className="h-md-100">
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Sending limits</h5>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <Row className="col-12">
          <Col md={6} className="border-end">
            <p className="mb-2">Daily sending quota</p>
            <p className="m-0 text-black">50,000 emails per 24-hour period</p>
          </Col>
          <Col md={6}>
            <p className="mb-2">Maximum send rate</p>
            <p className="m-0 text-black">14 emails per second</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SentEmailVolume;
