import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Col, Form, Row, Table } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import WizardInput from 'components/wizard/WizardInput';
import { ExecutionType, frequency } from 'data/common';
import DateTimePicker from 'react-datetime-picker';
import dayjs from 'dayjs';
import SubtleBadge from 'components/common/SubtleBadge';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';

const ExecutionForm = ({
  index = 1,
  execution,
  onChange,
  campaign,
  templates,
  segments,
  isViewingCampaign,
  fetchContacts,
  loadingContact,
  errors,
  control,
  setValue,
  clearErrors,
  register
}) => {
  const type = execution.type || ExecutionType.AUTOMATIC;

  return (
    <React.Fragment>
      <Form.Group className="mb-3">
        <Form.Label>Start date</Form.Label>

        {execution.scheduledStartDate && (
          <Controller
            name={`${type}-scheduledStartDate-${index}`}
            control={control}
            rules={{ required: 'Please select scheduled start date' }}
            defaultValue={execution.scheduledStartDate}
            render={({ field }) => (
              <DateTimePicker
                {...field}
                name={`${type}-scheduledStartDate-${index}`}
                value={execution.scheduledStartDate}
                onChange={date => {
                  if (!date) return;
                  onChange(execution, {
                    ...execution,
                    scheduledStartDate: date
                  });
                  setValue(`${type}-scheduledStartDate-${index}`, date);
                  clearErrors(`${type}-scheduledStartDate-${index}`);
                }}
                className={`form-control ${
                  errors[`${type}-scheduledStartDate-${index}`]
                    ? 'is-invalid'
                    : ''
                }`}
                disabled={isViewingCampaign}
              />
            )}
          />
        )}

        <Form.Control.Feedback type="invalid">
          {errors[`${type}-scheduledStartDate-${index}`]?.message}
        </Form.Control.Feedback>
      </Form.Group>
      {campaign.campaignType === 'Recurring' &&
        type === ExecutionType.MANUAL && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>End date</Form.Label>

              {execution.endDate && (
                <Controller
                  name={`${type}-endDate-${index}`}
                  control={control}
                  rules={{
                    required: 'Please select end date',
                    validate: value => {
                      if (
                        dayjs(value).isBefore(
                          dayjs(execution.scheduledStartDate)
                        )
                      ) {
                        return 'End date should be after start date';
                      }
                      return true;
                    }
                  }}
                  defaultValue={execution.endDate}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      name={`${type}-endDate-${index}`}
                      value={execution.endDate}
                      onChange={date => {
                        if (!date) return;
                        onChange(execution, {
                          ...execution,
                          endDate: date
                        });
                        setValue(`${type}-endDate-${index}`, date);
                        clearErrors(`${type}-endDate-${index}`);
                      }}
                      className={`form-control ${
                        errors[`${type}-endDate-${index}`] ? 'is-invalid' : ''
                      }`}
                      disabled={isViewingCampaign}
                    />
                  )}
                />
              )}

              <Form.Control.Feedback type="invalid">
                {errors[`${type}-endDate-${index}`]?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Frequency</Form.Label>
              <Controller
                name={`${type}-frequency-${index}`}
                control={control}
                rules={{ required: 'Please select frequency' }}
                defaultValue={execution.frequency}
                render={({ field }) => (
                  <Form.Select
                    {...field}
                    type="select"
                    value={execution.frequency}
                    onChange={e => {
                      onChange(execution, {
                        ...execution,
                        frequency: e.target.value
                      });
                      setValue(`${type}-frequency-${index}`, e.target.value);
                    }}
                    name={`${type}-frequency-${index}`}
                    disabled={isViewingCampaign}
                  >
                    <option value="">Select frequency</option>
                    {frequency.map(option => (
                      <option value={option} key={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                )}
              />
              {errors[`${type}-frequency-${index}`] && (
                <div className="invalid-feedback d-block">
                  {errors[`${type}-frequency-${index}`].message}
                </div>
              )}
            </Form.Group>
          </>
        )}

      <Row className=" mb-3">
        {campaign.communicationTypes.map((communicationType, i) => (
          <Col
            md={campaign.communicationTypes.length === 1 ? 12 : 6}
            key={`${type}-communicationTypes-${index}-${i}`}
          >
            <div className="box">
              <Form.Check type="checkbox" className="mb-1 ">
                <Form.Check.Input
                  type="checkbox"
                  name={`${type}-communicationTypes-${communicationType}-${index}`}
                  checked={
                    execution.campaignCommunications.find(
                      item => item.communicationType === communicationType
                    ).enabled
                  }
                  onChange={e => {
                    onChange(execution, {
                      ...execution,
                      campaignCommunications:
                        execution.campaignCommunications.map(item =>
                          item.communicationType === communicationType
                            ? {
                                ...item,
                                enabled: e.target.checked,
                                templateId: ''
                              }
                            : item
                        )
                    });

                    clearErrors(
                      `${type}-template-${communicationType}-${index}`
                    );
                  }}
                  disabled={isViewingCampaign}
                />
                <Form.Check.Label className="mb-0 text-700">
                  {communicationType}
                </Form.Check.Label>
              </Form.Check>
              <Form.Group>
                <Controller
                  name={`${type}-template-${communicationType}-${index}`}
                  control={control}
                  rules={{ required: 'Please select template' }}
                  defaultValue={
                    execution.campaignCommunications.find(
                      item => item.communicationType === communicationType
                    ).templateId
                  }
                  disabled={
                    !execution.campaignCommunications.find(
                      item => item.communicationType === communicationType
                    ).enabled
                  }
                  render={({ field }) => (
                    <Form.Select
                      {...field}
                      type="select"
                      value={
                        execution.campaignCommunications.find(
                          item => item.communicationType === communicationType
                        ).templateId
                      }
                      onChange={e => {
                        onChange(execution, {
                          ...execution,
                          campaignCommunications:
                            execution.campaignCommunications.map(item =>
                              item.communicationType === communicationType
                                ? { ...item, templateId: e.target.value }
                                : item
                            )
                        });
                        setValue(
                          `${type}-template-${communicationType}-${index}`,
                          e.target.value
                        );
                        clearErrors(
                          `${type}-template-${communicationType}-${index}`
                        );
                      }}
                      name={`${type}-template-${communicationType}-${index}`}
                      disabled={isViewingCampaign}
                    >
                      <option value="">Select template</option>
                      {templates
                        .filter(
                          item =>
                            item.type.toLowerCase() ===
                            communicationType.toLowerCase()
                        )
                        .map(option => (
                          <option value={option.id} key={option}>
                            {option.name}
                          </option>
                        ))}
                    </Form.Select>
                  )}
                />
                <div
                  className={classNames(
                    'invalid-feedback d-block',
                    errors[`${type}-template-${communicationType}-${index}`] &&
                      errors[`${type}-template-${communicationType}-${index}`]
                        .message
                      ? 'opacity-100'
                      : 'opacity-0'
                  )}
                >
                  Please select template
                </div>
              </Form.Group>
              {communicationType === 'Email' && (
                <WizardInput
                  type="text"
                  errors={
                    execution.campaignCommunications.find(
                      item => item.communicationType === communicationType
                    ).enabled
                      ? errors
                      : false
                  }
                  label="Subject"
                  name={`${type}-subject-${communicationType}-${index}`}
                  formControlProps={{
                    className: 'input-spin-none mb-3',
                    ...register(
                      `${type}-subject-${communicationType}-${index}`,
                      {
                        required: execution.campaignCommunications.find(
                          item => item.communicationType === communicationType
                        ).enabled
                          ? 'Please enter subject'
                          : ''
                      }
                    ),
                    value: execution.campaignCommunications.find(
                      item => item.communicationType === communicationType
                    ).title,
                    onChange: e => {
                      onChange(execution, {
                        ...execution,
                        campaignCommunications:
                          execution.campaignCommunications.map(item =>
                            item.communicationType === communicationType
                              ? { ...item, title: e.target.value }
                              : item
                          )
                      });
                    },
                    disabled: isViewingCampaign
                  }}
                />
              )}
            </div>
          </Col>
        ))}
        {!execution.campaignCommunications.find(item => item.enabled) && (
          <div className={classNames('invalid-feedback d-block')}>
            Please select at least one communication type
          </div>
        )}

        <div className="mb-3">
          <Form.Label>Segments</Form.Label>
          <Controller
            name={`${type}-segmentIds-${index}`}
            control={control}
            rules={{ required: 'Please select segment' }}
            defaultValue={execution.segmentIds}
            render={({ field }) => (
              <Select
                {...field}
                options={segments.map(item => ({
                  value: item.id,
                  label: item.segmentName
                }))}
                placeholder="Select segment channel"
                isMulti
                classNamePrefix="react-select"
                name={`${type}-segmentIds-${index}`}
                value={execution.segmentIds.map(item => ({
                  value: item,
                  label:
                    segments.find(segment => segment.id === item)
                      ?.segmentName || ''
                }))}
                onChange={data => {
                  onChange(execution, {
                    ...execution,
                    segmentIds: data.map(item => item.value)
                  });
                  setValue(`${type}-segmentIds-${index}`, data);
                  clearErrors(`${type}-segmentIds-${index}`);
                  const updatedCampaign = {
                    ...campaign,
                    campaignExecutions: campaign.campaignExecutions.map(item =>
                      item === execution
                        ? {
                            ...execution,
                            segmentIds: data.map(item => item.value)
                          }
                        : item
                    )
                  };
                  fetchContacts(updatedCampaign);
                }}
                isDisabled={isViewingCampaign}
              />
            )}
          />
          {errors[`${type}-segmentIds-${index}`] && (
            <div className="invalid-feedback d-block">
              {errors[`${type}-segmentIds-${index}`].message}
            </div>
          )}
        </div>
        <div className="mb-3">
          <Form.Label>Segments results </Form.Label>
          <Contacts
            execution={execution}
            segments={segments}
            loadingContact={loadingContact}
          />
        </div>
      </Row>
    </React.Fragment>
  );
};

const Contacts = ({ execution, segments, loadingContact }) => {
  const getSegmentName = segmentId => {
    if (segments && segments.length > 0) {
      const segment = segments.find(segment => segment.id === segmentId);
      return segment ? segment.segmentName : '';
    }
    return '';
  };
  const [contactGroups, setContactGroups] = React.useState([]);
  React.useEffect(() => {
    if (!execution || !execution.contacts || execution.contacts.length === 0)
      return;
    const _newContacts = [];
    execution.segmentIds.forEach(segmentId => {
      const segmentContacts = execution.contacts.filter(contact =>
        contact.segmentIds.includes(segmentId)
      );
      _newContacts.push({
        segmentIds: [segmentId],
        contacts: segmentContacts
      });
    });

    setContactGroups(_newContacts);
  }, [execution]);

  return (
    <div className="segment-row">
      {loadingContact ? (
        <Row className="justify-content-center mb-3 g-3">
          <Col lg={4} className="d-flex justify-content-center">
            <Lottie
              animationData={infiniteLoop}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
          <h3 className="text-center">Loading...</h3>
        </Row>
      ) : (
        <>
          {execution && execution.contacts && execution.contacts.length > 0 && (
            <i className="mb-0 fs-10">
              {execution.contacts.length} contact
              {execution.contacts.length > 1 ? 's' : ''} found{' '}
            </i>
          )}
          <div style={{ overflowX: 'auto' }} className="px-2 segment-table">
            <Table responsive>
              {execution.segmentIds.length > 0 && (
                <thead>
                  <tr className="border-2">
                    <th scope="col" width="80">
                      First name
                    </th>
                    <th scope="col" width="80">
                      Last name
                    </th>
                    <th scope="col" width="300">
                      Email
                    </th>
                    <th scope="col" width="120">
                      Phone number
                    </th>
                    <th scope="col" width="70" className="text-center">
                      Text Opt-in
                    </th>
                    <th scope="col" width="70" className="text-center">
                      Email Opt-in
                    </th>
                    <th scope="col" width="170">
                      Custom fields
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {execution &&
                execution.contacts &&
                execution.contacts.length > 0
                  ? contactGroups.map((group, index) => (
                      <tr key={index}>
                        <td colSpan="7" className="sub-title">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey={`contact-group-${index}`}>
                              <Accordion.Header>
                                <div>
                                  {group.segmentIds.map(segmentId => (
                                    <SubtleBadge
                                      bg="primary"
                                      key={segmentId}
                                      className="me-2"
                                    >
                                      {getSegmentName(segmentId)}
                                    </SubtleBadge>
                                  ))}
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <Table responsive>
                                  <tbody>
                                    {group.contacts.map((contact, index) => (
                                      <tr key={`contact-${index}`}>
                                        <td width="80">{contact.firstName}</td>
                                        <td width="80">{contact.lastName}</td>
                                        <td width="300">{contact.email}</td>
                                        <td width="120">
                                          {contact.phoneNumber}
                                        </td>
                                        <td width="70" className="text-center">
                                          {contact.textOptIn ? (
                                            <GoCheckCircle className="text-success fs-9" />
                                          ) : (
                                            <IoIosCloseCircleOutline className="text-danger fs-8" />
                                          )}
                                        </td>
                                        <td width="70" className="text-center">
                                          {contact.emailOptIn ? (
                                            <GoCheckCircle className="text-success fs-9 " />
                                          ) : (
                                            <IoIosCloseCircleOutline className="text-danger fs-8" />
                                          )}
                                        </td>
                                        <td
                                          width="170"
                                          className="text-capitalize"
                                        >
                                          {contact.customFields &&
                                            Object.keys(
                                              contact.customFields
                                            ).map((field, index) => (
                                              <div
                                                key={index}
                                                className="d-flex gap-2"
                                              >
                                                <span className="text-capitalize">
                                                  {field.replaceAll('_', ' ')}:
                                                </span>

                                                <i>
                                                  {contact.customFields[
                                                    field
                                                  ].toString()}
                                                </i>
                                              </div>
                                            ))}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </td>
                      </tr>
                    ))
                  : execution.segmentIds.length > 0 && (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No contacts found
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

Contacts.propTypes = {
  execution: PropTypes.object,
  segments: PropTypes.array,
  loadingContact: PropTypes.bool
};

ExecutionForm.propTypes = {
  key: PropTypes.string,
  index: PropTypes.number,
  execution: PropTypes.object,
  onChange: PropTypes.func,
  campaign: PropTypes.object,
  templates: PropTypes.array,
  segments: PropTypes.array,
  isViewingCampaign: PropTypes.bool,
  fetchContacts: PropTypes.func,
  loadingContact: PropTypes.bool,
  errors: PropTypes.object,
  setError: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  register: PropTypes.func
};

export default ExecutionForm;
