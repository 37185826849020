import AddSenderModal from 'components/senders/AddSenderModal';
import DeleteSenderModal from 'components/senders/DeleteSenderModal';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import { AiFillDelete } from 'react-icons/ai';
import { FaCheckSquare, FaMinusSquare, FaRegSquare } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';

const SendersPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const [currentSenders, setCurrentSenders] = useState([]);
  const [showAddSenderModal, setShowAddSenderModal] = useState(false);

  const [showDeleteSenderModal, setShowDeleteSenderModal] = useState(false);
  const [deletingSenders, setDeletingSenders] = useState([]);

  const selectedSendersCount = currentSenders.filter(
    item => item.selected
  ).length;

  const onSenderClick = index => {
    const updatedSenders = [...currentSenders];
    updatedSenders[index].selected = !updatedSenders[index].selected;
    setCurrentSenders(updatedSenders);
  };

  const onHeaderCheckBoxClick = () => {
    if (selectedSendersCount === currentSenders.length) {
      setCurrentSenders(
        currentSenders.map(sender => ({
          ...sender,
          selected: false
        }))
      );
    } else {
      setCurrentSenders(
        currentSenders.map(sender => ({
          ...sender,
          selected: true
        }))
      );
    }
  };

  const fetchSenders = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get('/email-account');
      setCurrentSenders(response.data);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch senders');
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchSenders();
  }, []);

  const handleCloseDeleteModal = () => {
    setShowDeleteSenderModal(false);
    setDeletingSenders([]);
  };

  const handleSubmitDeleteModal = async () => {
    setIsLoading(true);

    try {
      await axiosClient.delete(
        `/email-account?senders=${decodeURIComponent(
          JSON.stringify(deletingSenders)
        )}`
      );
      fetchSenders();
      toast.success('Sender deleted successfully');
      setShowDeleteSenderModal(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to delete sender');
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 d-flex justify-content-between border-bottom">
              <h5 className="mb-0 mt-2">Senders</h5>
              <div className="d-flex gap-2">
                <Button
                  variant="outline-danger"
                  className={`${!selectedSendersCount ? 'disable' : ''}`}
                  disabled={!selectedSendersCount}
                  onClick={() => {
                    setDeletingSenders(
                      currentSenders.filter(sender => sender.selected)
                    );
                    setShowDeleteSenderModal(true);
                  }}
                >
                  <AiFillDelete />
                  {` `}
                  Delete
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => setShowAddSenderModal(true)}
                >
                  <FaPlus />
                  {` `}
                  Create
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || currentSenders.length < 0
                      ? 'calc(100vh - 210px)'
                      : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className=" cursor-pointer"
                      onClick={onHeaderCheckBoxClick}
                    >
                      {selectedSendersCount > 0 ? (
                        selectedSendersCount === currentSenders.length ? (
                          <FaCheckSquare className="text-primary" />
                        ) : (
                          <FaMinusSquare className="text-primary" />
                        )
                      ) : (
                        <FaRegSquare />
                      )}
                    </th>
                    <th scope="col">Sender</th>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : currentSenders.length > 0 ? (
                    currentSenders.map((sender, index) => (
                      <tr
                        key={`sender-${index}`}
                        onClick={() => onSenderClick(index)}
                        className={`${sender.selected ? 'bg-light' : ''}`}
                      >
                        <td>
                          {sender.selected ? (
                            <FaCheckSquare className="text-primary fs-9" />
                          ) : (
                            <FaRegSquare className="fs-9" />
                          )}
                        </td>
                        <td>
                          <Link to={`/senders/${sender.id}`}>
                            {sender.name}
                          </Link>
                        </td>
                        <td>{sender.type}</td>
                        <td
                          className={`d-flex align-items-center ${
                            sender.isVerified ? 'text-success' : 'text-danger'
                          }`}
                        >
                          {sender.isVerified ? (
                            <GoCheckCircle className="fs-9 me-1" />
                          ) : (
                            <IoIosCloseCircleOutline className="fs-8 me-1" />
                          )}
                          {sender.isVerified ? 'Verified' : 'Unverified'}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No senders found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddSenderModal
        showAddSenderModal={showAddSenderModal}
        setShowAddSenderModal={setShowAddSenderModal}
        fetchSenders={fetchSenders}
      />
      {deletingSenders && showDeleteSenderModal && (
        <DeleteSenderModal
          openModal={showDeleteSenderModal}
          handleCloseModal={handleCloseDeleteModal}
          data={deletingSenders}
          handleSubmitModal={handleSubmitDeleteModal}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default SendersPage;
