import WizardInput from 'components/wizard/WizardInput';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

import EmailEditor from 'react-email-editor';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateTemplatesPage = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();
  const { id } = useParams();

  const emailEditorRef = useRef(null);
  const [template, setTemplate] = useState({
    name: '',
    content: null
  });
  const [loading, setLoading] = useState(false);

  const onReady = () => {};

  const onSubmit = () => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const { design, html } = data;
      const _template = {
        ...template,
        content: {
          design: JSON.stringify(design),
          html
        }
      };
      setLoading(true);
      try {
        await axiosClient.put(`/templates/${id}`, _template);
        toast.success('Template updated successfully');
        navigate('/templates/email');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to update template');
        }
      }
    });
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      setLoading(true);
      try {
        const response = await axiosClient.get(`/templates/${id}`);
        setTemplate(response.data);
        setValue('name', response.data.name);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to fetch template');
        }
      }
    };
    if (id) {
      fetchTemplate();
    }
  }, [id]);

  useEffect(() => {
    if (
      emailEditorRef &&
      emailEditorRef.current &&
      template &&
      template.content &&
      template.content.design
    ) {
      emailEditorRef.current.editor.loadDesign(
        JSON.parse(template.content.design)
      );
    }
  }, [emailEditorRef, template]);

  return (
    <Card>
      <Card.Header className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom">
        <Card.Title as="h5" id="contained-modal-title-vcenter">
          Update email template
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
          <WizardInput
            label="Template name"
            name="name"
            errors={errors}
            formGroupProps={{ className: 'col-6 mb-3' }}
            formControlProps={{
              ...register('name', {
                required: 'Template name is required'
              }),
              onChange: e => {
                setTemplate({
                  ...template,
                  name: e.target.value
                });
              },
              value: template.name
            }}
          />
          <Form.Label>Content</Form.Label>
          <EmailEditor
            id="emailEditor"
            ref={emailEditorRef}
            onReady={onReady}
            style={{ marginLeft: '-20px' }}
          />
          <Button
            className="mt-4 mb-2 mx-auto"
            type="submit"
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default UpdateTemplatesPage;
