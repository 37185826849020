import ProfileBanner from 'components/pages/user/ProfileBanner';
import React, { useEffect } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { getUser, selectUser } from 'redux/reducer/auth';
import { useAppDispatch, useAppSelector } from 'redux/store';
import gmail from 'assets/img/icons/mail.png';
import website from 'assets/img/icons/website.png';
import phone from 'assets/img/icons/phone.png';
import bgGrey from 'assets/img/icons/grey-bg.png';
import userAvatar from 'assets/img/icons/user.png';
import Flex from 'components/common/Flex';
import WizardInput from 'components/wizard/WizardInput';
import { useForm } from 'react-hook-form';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import { FaCamera } from 'react-icons/fa';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { businessTypes } from 'data/businessTypes';

const rightSidedItems = [
  {
    title: 'email',
    image: gmail
  },
  {
    title: 'website',
    image: website
  },
  {
    title: 'phoneNumber',
    image: phone
  }
];

const Profile = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors
  } = useForm();

  const [loading, setLoading] = React.useState(false);
  const [updateUser, setUpdateUser] = React.useState(user || {});
  const [showUpdateProfileModal, setShowUpdateProfileModal] =
    React.useState(false);

  const [companyLogo, setCompanyLogo] = React.useState(bgGrey);
  const [avatar, setAvatar] = React.useState(userAvatar);

  const [isOtherSelected, setIsOtherSelected] = React.useState(false);

  const handleBusinessTypeChange = event => {
    setIsOtherSelected(event.target.value === 'Other');
  };

  const handleCompanyLogoUpload = event => {
    const file = event.target.files[0];
    setUpdateUser({
      ...updateUser,
      companyLogo: file
    });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCompanyLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarUpload = event => {
    const file = event.target.files[0];
    setUpdateUser({
      ...updateUser,
      avatar: file
    });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateProfile = () => {
    handleSubmit(async () => {
      setLoading(true);

      const formData = new FormData();

      if (avatar) {
        formData.append('avatar', updateUser.avatar);
      }

      if (companyLogo) {
        formData.append('companyLogo', updateUser.companyLogo);
      }

      formData.append('fullName', updateUser.fullName);
      formData.append('website', updateUser.website);
      formData.append('tierPlan', updateUser.tierPlan);
      formData.append('phoneNumber', updateUser.phoneNumber);
      formData.append('businessName', updateUser.businessName);
      formData.append('businessAddress', updateUser.businessAddress);
      formData.append('city', updateUser.city);
      formData.append('state', updateUser.state);
      formData.append('zipCode', updateUser.zipCode);
      formData.append(
        'businessType',
        updateUser.businessType === 'Other' && updateUser.otherBusinessType
          ? updateUser.otherBusinessType
          : updateUser.businessType
      );
      console.log(updateUser.zipCode);

      try {
        const response = await axiosClient.put(
          '/auth/update-profile',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        toast.success(response.data.message);
        setShowUpdateProfileModal(false);
        dispatch(getUser());
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error updating profile:', error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        }
      }
      reset();
      clearErrors();
    })();
  };

  useEffect(() => {
    if (!user) return;

    setAvatar(user.avatar || userAvatar);
    setCompanyLogo(user.companyLogo || bgGrey);

    const isBusinessTypeOther = !businessTypes.includes(user.businessType);

    const newUser = {
      ...user,
      businessType: isBusinessTypeOther ? 'Other' : user.businessType,
      otherBusinessType: isBusinessTypeOther ? user.businessType : ''
    };

    setIsOtherSelected(isBusinessTypeOther);
    setUpdateUser(newUser);
  }, [user]);
  return (
    user && (
      <>
        <ProfileBanner>
          <ProfileBanner.Header
            avatar={user.avatar || userAvatar}
            coverSrc={user.companyLogo || bgGrey}
          />
          <ProfileBanner.Body>
            <Row>
              <Col lg={8}>
                <h4 className="mb-1">{user.fullName}</h4>
                <h5 className="fs-9 fw-normal">{user.businessName}</h5>
                <p className="text-500"> {user.businessAddress}</p>
                <p> Tier plan: {user.tierPlan}</p>
                <Button onClick={() => setShowUpdateProfileModal(true)}>
                  Edit profile
                </Button>
                <div className="border-dashed border-bottom my-4 d-lg-none" />
              </Col>
              <Col className="ps-2 ps-lg-3">
                {rightSidedItems.map(item => (
                  <Flex alignItems="center" className="mb-2" key={item.title}>
                    <img
                      src={item.image}
                      alt={item.title}
                      width={30}
                      className="me-2"
                    />
                    <div className="flex-1">
                      <h6 className="mb-0">{user[item.title]}</h6>
                    </div>
                  </Flex>
                ))}
              </Col>
            </Row>
          </ProfileBanner.Body>
        </ProfileBanner>
        {user && (
          <Modal
            show={showUpdateProfileModal}
            onHide={() => setShowUpdateProfileModal(false)}
            size="lg"
            centered
          >
            <Modal.Body>
              {loading ? (
                <Row className="justify-content-center mb-3 g-3">
                  <Col lg={4} className="d-flex justify-content-center">
                    <Lottie
                      animationData={infiniteLoop}
                      loop={true}
                      style={{ height: '120px', width: '120px' }}
                    />
                  </Col>
                  <h3 className="text-center">Loading...</h3>
                </Row>
              ) : (
                <Card className="shadow-none">
                  <Card.Header className="position-relative min-vh-25 mb-7 p-0">
                    <div
                      className="col-12 position-relative"
                      style={{ height: 270 }}
                    >
                      <div
                        className="position-absolute bg-200 z-1 p-2 rounded-circle cursor-pointer"
                        style={{ bottom: 12, right: 12 }}
                        onClick={() =>
                          document.getElementById('companyLogo').click()
                        }
                      >
                        <FaCamera className="fs-7" />
                      </div>
                      <input
                        type="file"
                        id="companyLogo"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={handleCompanyLogoUpload}
                      />
                      <Background
                        image={companyLogo}
                        className="rounded-3 rounded-bottom-0 "
                      />
                    </div>

                    <div className="avatar-profile">
                      <div className="position-relative">
                        <div
                          className="position-absolute bg-200 z-1 p-2 rounded-circle cursor-pointer"
                          style={{ bottom: 12, right: 12 }}
                          onClick={() =>
                            document.getElementById('avatar').click()
                          }
                        >
                          <FaCamera className="fs-7" />
                        </div>
                        <input
                          type="file"
                          id="avatar"
                          style={{ display: 'none' }}
                          accept="image/*"
                          onChange={handleAvatarUpload}
                        />
                        <Avatar
                          size="5xl"
                          src={avatar}
                          mediaClass="img-thumbnail shadow-sm"
                        />
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex flex-column">
                      <WizardInput
                        label="Full name"
                        name="fullName"
                        errors={errors}
                        formGroupProps={{ className: 'mb-3' }}
                        formControlProps={{
                          ...register('fullName', {
                            required: 'Full name is required'
                          }),
                          value: updateUser.fullName,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              fullName: e.target.value
                            });
                            clearErrors('fullName');
                          }
                        }}
                      />
                      <WizardInput
                        type="text"
                        label="Website"
                        name="website"
                        errors={errors}
                        formGroupProps={{
                          className: 'mb-3'
                        }}
                        formControlProps={{
                          className: 'input-spin-none',
                          ...register('website', {
                            required: 'Website is required',
                            pattern: {
                              value: /^(https):\/\/[^ "]+$/i,
                              message:
                                'Website URL must be valid (e.g. https://example.com)'
                            }
                          }),
                          value: updateUser.website,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              website: e.target.value
                            });
                            clearErrors('website');
                          }
                        }}
                      />

                      <WizardInput
                        type="select"
                        label="Tier plan"
                        name="tierPlan"
                        placeholder="Select tier plan..."
                        errors={errors}
                        options={['Tier 1', 'Tier 2', 'Tier 3']}
                        formGroupProps={{
                          className: 'mb-3'
                        }}
                        formControlProps={{
                          ...register('tierPlan', {
                            required: 'Tier plan is required'
                          }),
                          value: updateUser.tierPlan,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              tierPlan: e.target.value
                            });
                            clearErrors('tierPlan');
                          }
                        }}
                      />
                      <WizardInput
                        type="text"
                        label="Phone number"
                        name="phoneNumber"
                        errors={errors}
                        formGroupProps={{
                          className: 'mb-3'
                        }}
                        formControlProps={{
                          className: 'input-spin-none',
                          ...register('phoneNumber', {
                            required: 'Phone number is required'
                          }),
                          value: updateUser.phoneNumber,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              phoneNumber: e.target.value
                            });
                            clearErrors('phoneNumber');
                          }
                        }}
                      />
                      <WizardInput
                        type="text"
                        label="Business name"
                        name="businessName"
                        errors={errors}
                        formGroupProps={{
                          className: 'mb-3'
                        }}
                        formControlProps={{
                          className: 'input-spin-none',
                          ...register('businessName', {
                            required: 'Business name is required'
                          }),
                          value: updateUser.businessName,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              businessName: e.target.value
                            });
                            clearErrors('businessName');
                          }
                        }}
                      />

                      <WizardInput
                        type="textarea"
                        label="Business Address"
                        name="businessAddress"
                        errors={errors}
                        formGroupProps={{
                          className: 'mb-3'
                        }}
                        formControlProps={{
                          ...register('businessAddress', {
                            required: 'Business address is required'
                          }),
                          value: updateUser.businessAddress,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              businessAddress: e.target.value
                            });
                            clearErrors('businessAddress');
                          }
                        }}
                      />

                      <WizardInput
                        type="text"
                        label="Zip Code"
                        name="zipCode"
                        errors={errors}
                        formGroupProps={{
                          className: 'mb-3'
                        }}
                        formControlProps={{
                          className: 'input-spin-none',
                          ...register('zipCode', {
                            required: 'Zip Code is required',
                            pattern: {
                              value: /^\d{5}(-\d{4})?$/,
                              message: 'Zip Code must be valid'
                            }
                          }),
                          value: updateUser.zipCode,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              zipCode: e.target.value
                            });
                            clearErrors('zipCode');
                          }
                        }}
                      />

                      <WizardInput
                        type="text"
                        label="State"
                        name="state"
                        errors={errors}
                        formGroupProps={{
                          className: 'mb-3'
                        }}
                        formControlProps={{
                          className: 'input-spin-none',
                          ...register('state', {
                            required: 'State is required'
                          }),
                          value: updateUser.state,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              state: e.target.value
                            });
                            clearErrors('state');
                          }
                        }}
                      />

                      <WizardInput
                        type="text"
                        label="City"
                        name="city"
                        errors={errors}
                        formGroupProps={{
                          className: 'mb-3'
                        }}
                        formControlProps={{
                          className: 'input-spin-none',
                          ...register('city', {
                            required: 'City is required'
                          }),
                          value: updateUser.city,
                          onChange: e => {
                            setUpdateUser({
                              ...updateUser,
                              city: e.target.value
                            });
                            clearErrors('city');
                          }
                        }}
                      />
                      <Row className="d-flex flex-row">
                        <WizardInput
                          type="select"
                          label="Business Type"
                          name="businessType"
                          placeholder="Select business type..."
                          errors={errors}
                          options={businessTypes}
                          formGroupProps={{
                            className: `mb-3 ${isOtherSelected ? 'w-50' : ''}`
                          }}
                          formControlProps={{
                            ...register('businessType', {
                              required: 'Business type is required',
                              onChange: e => {
                                handleBusinessTypeChange(e);
                              }
                            }),
                            value: updateUser.businessType,
                            onChange: e => {
                              setUpdateUser({
                                ...updateUser,
                                businessType: e.target.value
                              });
                              handleBusinessTypeChange(e);
                              clearErrors('businessType');
                            }
                          }}
                        />

                        {isOtherSelected && (
                          <WizardInput
                            type="text"
                            label="Specify Other Business Type"
                            name="otherBusinessType"
                            errors={errors}
                            formGroupProps={{
                              className: `mb-3 ${isOtherSelected ? 'w-50' : ''}`
                            }}
                            formControlProps={{
                              className: 'input-spin-none',
                              ...register('otherBusinessType', {
                                required: 'Please specify your business type'
                              }),
                              value: updateUser.otherBusinessType,
                              onChange: e => {
                                setUpdateUser({
                                  ...updateUser,
                                  otherBusinessType: e.target.value
                                });
                                clearErrors('otherBusinessType');
                              }
                            }}
                          />
                        )}
                      </Row>
                    </div>
                  </Card.Body>
                  <div className="d-flex mx-auto gap-3 ">
                    <Button
                      variant="secondary"
                      onClick={() => setShowUpdateProfileModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button onClick={handleUpdateProfile}>Save</Button>
                  </div>
                </Card>
              )}
            </Modal.Body>
          </Modal>
        )}
      </>
    )
  );
};

export default Profile;
