import WizardInput from 'components/wizard/WizardInput';
import React, { useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

import EmailEditor from 'react-email-editor';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const CreateTemplatesPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();

  const emailEditorRef = useRef(null);
  const [newTemplate, setNewTemplate] = useState({
    name: '',
    content: null
  });

  const onReady = () => {
    emailEditorRef.current.editor.exportHtml(data => {
      const { html } = data;
      console.log('exportHtml', html);
    });
  };

  const onSubmit = () => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const { design, html } = data;
      const template = {
        ...newTemplate,
        content: {
          design: JSON.stringify(design),
          html: html
        }
      };
      try {
        await axiosClient.post(`/templates`, template);
        toast.success('Template created successfully');
        navigate('/templates/email');
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Failed to create template');
        }
      }
    });
  };

  return (
    <Card>
      <Card.Header className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom">
        <Card.Title as="h5" id="contained-modal-title-vcenter">
          Create email template
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
          <WizardInput
            label="Template name"
            name="name"
            errors={errors}
            formGroupProps={{ className: 'col-6 mb-3' }}
            formControlProps={{
              ...register('name', {
                required: 'Template name is required'
              }),
              onChange: e => {
                setNewTemplate({
                  ...newTemplate,
                  name: e.target.value
                });
              },
              value: newTemplate.name
            }}
          />
          <Form.Label>Content</Form.Label>
          <EmailEditor
            ref={emailEditorRef}
            onReady={onReady}
            style={{ marginLeft: '-20px' }}
          />
          <Button className="mt-4 mb-2 mx-auto" type="submit">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CreateTemplatesPage;
