import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Modal, Nav, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';

import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import CampaignForm from './wizard/CampaignForm';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';
import ScheduleForm from './wizard/ScheduleForm';
import Review from './wizard/Review';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Notifications from './wizard/Notifications';
import { ExecutionType } from 'data/common';

const CAMPAIGN_TAB = 'Campaign';
const NOTIFICATION_TAB = 'Notification';

const UpdateCampaignModal = ({
  showUpdateCampaignModal,
  setShowUpdateCampaignModal,
  fetchCampaigns,
  updatingCampaign,
  emailSenders,
  textSenders,
  templates,
  segments,
  isViewingCampaign
}) => {
  const {
    register,
    unregister,
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm();

  const navItems = [
    {
      icon: 'flag',
      label: 'Campaign'
    },
    {
      icon: 'user',
      label: 'Schedule'
    },
    {
      icon: 'thumbs-up',
      label: 'Review'
    }
  ];

  const user = useAppSelector(selectUser);
  const { isRTL } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [campaign, setCampaign] = useState(updatingCampaign);
  const [step, setStep] = useState(1);
  const [executionError, setExecutionError] = useState(null);
  const [tab, setTab] = useState(CAMPAIGN_TAB);

  const onSubmitData = async () => {
    setCampaign(campaign);
    if (step < 3) {
      if (
        step === 2 &&
        !campaign.campaignExecutions.find(execution =>
          execution.campaignCommunications.find(item => item.enabled)
        )
      ) {
        return;
      }
      if (step === 2 && campaign.campaignExecutions.length === 0) {
        setExecutionError('Please add at least one execution');
        toast.error('Please add at least one execution');
        return;
      } else {
        setExecutionError('');
      }
      setStep(step + 1);
    } else {
      setLoading(true);
      try {
        const _campaign = { ...campaign };
        _campaign.status = 'review';
        _campaign.campaignExecutions = campaign.campaignExecutions.map(item => {
          const execution = {
            ...item,
            contacts: [],
            scheduledStartDate: new Date(item.scheduledStartDate).toISOString(),
            campaignCommunications: item.campaignCommunications.filter(
              item => item.enabled
            )
          };
          if (item.endDate) {
            execution.endDate = new Date(item.endDate).toISOString();
          }
          return execution;
        });

        await axiosClient.put(`/campaigns/${_campaign.id}`, _campaign);
        toast.success('Campaign updated successfully');
        setShowUpdateCampaignModal(false);
        fetchCampaigns();
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Error to update campaign');
        }
      }
    }
  };

  const onError = () => {
    // if (!validation) {
    //   clearErrors();
    //   setStep(step + 1);
    // }
  };

  const handleNavs = targetStep => {
    if (targetStep < step) {
      setStep(targetStep);
    } else {
      onSubmitData();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setExecutionError(null);
    }, 3000);
    return () => clearTimeout(timer);
  }, [executionError]);

  const fetchContacts = async campaign => {
    setLoadingContact(true);
    try {
      const promise = [];
      campaign.campaignExecutions.map(item => {
        const _segments = [];
        item.segmentIds.forEach(segmentId => {
          const _segment = segments.find(item => item.id === segmentId);
          if (_segment) {
            _segments.push(_segment.criteria);
          }
        });
        promise.push(
          axiosClient.get(
            `/contacts/${user.id}?segments=${JSON.stringify(_segments)}`
          )
        );
      });
      const results = await Promise.all(promise);
      setCampaign({
        ...campaign,
        campaignExecutions: campaign.campaignExecutions.map((item, index) => ({
          ...item,
          contacts: results[index].data.contacts.map(contact => ({
            ...contact,
            selected: false
          }))
        }))
      });

      setLoadingContact(false);
    } catch (error) {
      setLoadingContact(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get contacts');
      }
    }
  };

  useEffect(() => {
    if (user && step === 2) {
      fetchContacts(campaign);
    }
    if (!(step === 2 && campaign.campaignExecutions.length === 0)) {
      setExecutionError(null);
    }
  }, [user, step]);

  useEffect(() => {
    setTab(CAMPAIGN_TAB);
    setCampaign(updatingCampaign);
    setStep(1);
    reset();
  }, [showUpdateCampaignModal]);

  useEffect(() => {
    campaign.campaignExecutions.forEach(execution => {
      const type = execution.type || ExecutionType.AUTOMATIC;
      execution.campaignCommunications.forEach(communication => {
        let field = `${type}-template-${communication.communicationType}-${execution.index}`;
        setValue(field, communication.templateId);
      });
    });
  }, [campaign.campaignExecutions]);

  const getStyle = data => {
    var activeStyle = {
      span: '',
      h6: '',
      svg: '#47576c'
    };
    if (data === tab) {
      activeStyle = {
        span: 'border rounded-pill bg-primary',
        h6: 'text-white',
        svg: '#eee'
      };
    }
    return activeStyle;
  };

  return (
    <>
      <Modal
        show={showUpdateCampaignModal}
        onHide={() => {
          setShowUpdateCampaignModal(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update campaign
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <Card
            as={Form}
            noValidate
            onSubmit={handleSubmit(onSubmitData, onError)}
            className="theme-wizard"
          >
            <Card.Header className={classNames('bg-body-tertiary pb-2')}>
              <Nav className="justify-content-center">
                {navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
              </Nav>
            </Card.Header>
            <Card.Body className="fw-normal p-0">
              {loading ? (
                <Row className="justify-content-center mb-3 g-3">
                  <Col lg={4} className="d-flex justify-content-center">
                    <Lottie
                      animationData={infiniteLoop}
                      loop={true}
                      style={{ height: '120px', width: '120px' }}
                    />
                  </Col>
                  <h3 className="text-center">Loading...</h3>
                </Row>
              ) : (
                <>
                  {step === 1 && (
                    <>
                      <span
                        className="d-flex border rounded-pill bg-300 mx-auto mt-3 border-400"
                        style={{ width: 'fit-content' }}
                      >
                        <>
                          <span
                            className={`d-flex align-items-center gap-1 px-3 py-2 text-500 cursor-pointer ${
                              getStyle(CAMPAIGN_TAB).span
                            }`}
                            onClick={() => setTab(CAMPAIGN_TAB)}
                          >
                            <h6
                              className={`m-auto ${getStyle(CAMPAIGN_TAB).h6}`}
                            >
                              {CAMPAIGN_TAB}
                            </h6>
                          </span>

                          <span
                            className={`d-flex align-items-center  gap-1 px-3 py-2 text-500 cursor-pointer ${
                              getStyle(NOTIFICATION_TAB).span
                            }`}
                            onClick={() => setTab(NOTIFICATION_TAB)}
                          >
                            <h6
                              className={`m-auto ${
                                getStyle(NOTIFICATION_TAB).h6
                              }`}
                            >
                              {NOTIFICATION_TAB}
                            </h6>
                          </span>
                        </>
                      </span>
                      <div className="px-4 px-md-6 pb-4">
                        {tab === CAMPAIGN_TAB && (
                          <CampaignForm
                            register={register}
                            errors={errors}
                            control={control}
                            setValue={setValue}
                            campaign={campaign}
                            setCampaign={setCampaign}
                            isViewingCampaign={isViewingCampaign}
                          />
                        )}
                        {tab === NOTIFICATION_TAB && (
                          <Notifications campaign={campaign} />
                        )}
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <div
                      style={{ maxHeight: 700 }}
                      className="overflow-y-auto px-4 px-md-6 pt-4 pb-0"
                    >
                      <ScheduleForm
                        register={register}
                        unregister={unregister}
                        errors={errors}
                        setError={setError}
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        clearErrors={clearErrors}
                        campaign={campaign}
                        setCampaign={setCampaign}
                        emailSenders={emailSenders}
                        textSenders={textSenders}
                        templates={templates}
                        segments={segments}
                        step={step}
                        isUpdate={true}
                        isViewingCampaign={isViewingCampaign}
                        fetchContacts={fetchContacts}
                        loadingContact={loadingContact}
                      />
                    </div>
                  )}
                  {step === 3 && (
                    <Review
                      register={register}
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      campaign={campaign}
                      setCampaign={setCampaign}
                      emailSenders={emailSenders}
                      textSenders={textSenders}
                      templates={templates}
                      segments={segments}
                      isViewingCampaign={isViewingCampaign}
                    />
                  )}
                  {executionError && (
                    <div
                      className="invalid-feedback d-block px-4 px-md-6 mt-2"
                      style={{ marginTop: -28 }}
                    >
                      {executionError}
                    </div>
                  )}
                </>
              )}
            </Card.Body>
            <Card.Footer
              className={classNames('px-md-6 bg-body-tertiary', {
                'd-none': step === 4,
                ' d-flex': step < 4
              })}
            >
              <IconButton
                variant="link"
                icon={isRTL ? 'chevron-right' : 'chevron-left'}
                iconAlign="left"
                transform="down-1 shrink-4"
                className={classNames('px-0 fw-semibold', {
                  'd-none': step === 1
                })}
                onClick={() => {
                  setStep(step - 1);
                }}
                disabled={loading}
              >
                Prev
              </IconButton>

              <IconButton
                variant="primary"
                className="px-2 ms-auto"
                type={isViewingCampaign ? 'button' : 'submit'}
                icon={
                  step < 3 ? (isRTL ? 'chevron-left' : 'chevron-right') : ''
                }
                iconAlign="right"
                transform="down-1 shrink-4"
                disabled={loading}
                onClick={() => {
                  if (isViewingCampaign) handleNavs();
                }}
              >
                {step === 3
                  ? isViewingCampaign
                    ? 'Cancel'
                    : 'Submit'
                  : 'Next'}
              </IconButton>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < 3 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

UpdateCampaignModal.propTypes = {
  showUpdateCampaignModal: PropTypes.bool.isRequired,
  setShowUpdateCampaignModal: PropTypes.func.isRequired,
  fetchCampaigns: PropTypes.func.isRequired,
  updatingCampaign: PropTypes.object,
  emailSenders: PropTypes.array,
  textSenders: PropTypes.array,
  templates: PropTypes.array,
  segments: PropTypes.array,
  isViewingCampaign: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default UpdateCampaignModal;
