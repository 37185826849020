import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import SimpleLogin from 'components/authentication/simple/Login';
import MainLayout from 'layouts/MainLayout';
import WizardAuth from 'components/authentication/wizard/WizardAuth';
import MainAccountDashboard from 'pages/dashboard';
import SendersPage from 'pages/senders';
import SenderDetailPage from 'pages/senders/[id]';
import ProtectedRoute from './ProtectedRoute';
import ContactsPage from 'pages/contacts';
import CustomFieldsPage from 'pages/custom-fields';
import CustomerSegmentsPage from 'pages/customer-segments';
import EmailTemplatesPage from 'pages/templates/email';
import CreateTemplatesPage from 'pages/templates/create';
import UpdateTemplatesPage from 'pages/templates/[id]';
import CampaignsPage from 'pages/campaigns';
import Profile from 'pages/profile';
import TextTemplatesPage from 'pages/templates/text';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="landing" element={<Landing />} />
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      <Route element={<AuthSimpleLayout />}>
        <Route path="/login" element={<SimpleLogin />} />
      </Route>
      <Route path="/register" element={<WizardAuth />} />
      <Route element={<MainLayout />}>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<MainAccountDashboard />} />
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/senders" element={<SendersPage />} />
          <Route path="/senders/:id" element={<SenderDetailPage />} />

          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/custom-fields" element={<CustomFieldsPage />} />
          <Route path="/customer-segments" element={<CustomerSegmentsPage />} />

          <Route path="/templates/email" element={<EmailTemplatesPage />} />
          <Route
            path="/templates/email/create"
            element={<CreateTemplatesPage />}
          />
          <Route
            path="/templates/email/:id"
            element={<UpdateTemplatesPage />}
          />
          <Route path="/templates/text" element={<TextTemplatesPage />} />

          <Route path="/campaigns" element={<CampaignsPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default AppRoutes;
